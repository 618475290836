import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip, Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@emotion/react";
import { tableDarkTheme, tableLightTheme } from "../shared/theming/table.theme";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import EditModal from "./EditModal.component";
import Delete from "@mui/icons-material/Delete";
import { DeleteRowModal } from "../shared/helper/tableHelper";

const DetailedConfig = ({
  row,
  userPermissions,
  theme,
  agents,
  updateConfig,
}) => {
  const { t, i18n } = useTranslation("config");
  const [typeModal, setTypeModal] = useState("edit");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [config, setConfig] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [agentNames, setAgentNames] = useState([]);

  useEffect(() => {
    if (row) {
      setConfig(row);
      let agentNames = agents.agents.map((agent) => agent.display_name);
      if (row.name === "flows_dynamic_start_intents") {
        row.value = convertAgentNamesAndIds(row.value, false);
      }
      setAgentNames(agentNames);
    }
  }, [row]);

  const objectArrayColumns = [
    {
      accessorKey: "text",
      header: "Text",
    },
    {
      accessorKey: "agent",
      header: "Agent",
    },
  ];

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditRow(null);
  };

  // Function to convert agent names to IDs and vice versa
  const convertAgentNamesAndIds = (values, toId = true) => {
    return values.map((value) => {
      if (toId) {
        let tempAgent = agents.agents.find(
          (agent) => agent.display_name === value.agent,
        );
        return { ...value, agent: tempAgent?._id };
      } else {
        let agentName = agents.agents.find(
          (agent) => agent._id === value.agent,
        )?.display_name;
        return { ...value, agent: agentName };
      }
    });
  };

  const handleDetailedSave = (values) => {
    if (typeModal === "new") {
      let tempConfig = { ...config };
      let tempValues = values.valueArray[0];
      tempConfig.value = [...tempConfig.value, tempValues];
      values.valueArray = convertAgentNamesAndIds(tempConfig.value, true);
      updateConfig(tempConfig, values);
    } else {
      let tempEditRow = { ...editRow };
      tempEditRow.name = values.name;
      tempEditRow._id = config._id;
      values.valueArray = convertAgentNamesAndIds(values.valueArray, true);
      updateConfig(tempEditRow, values);
    }
    handleEditModalClose();
  };

  const handleDelete = () => {
    let tempConfig = { ...config };
    let values = {
      name: tempConfig.name,
      type: tempConfig.type,
      valueArray: tempConfig.value.filter(
        (value) =>
          !(value.text === editRow.text && value.agent === editRow.agent),
      ),
    };
    values.valueArray = convertAgentNamesAndIds(values.valueArray, true);
    updateConfig(tempConfig, values);
    setDeleteModalOpen(false);
  };

  const handleEditOpenModal = (editRow) => {
    setEditModalOpen(true);
    setEditRow(editRow);
  };

  return (
    <React.Fragment>
      <EditModal
        open={editModalOpen}
        agentNames={agentNames}
        onClose={handleEditModalClose}
        //configs={data}
        data={{
          name: config?.name,
          type: config?.type,
          value: config?.value,
          rowIndex: editRow?.staticRowIndex,
        }}
        onDetailedSave={handleDetailedSave}
        typeModal={typeModal}
        isEditObjectArrayConfig
      />
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setEditRow(null);
        }}
        onDelete={handleDelete}
        title={t("tittleDeleteModal")}
      />
      <ThemeProvider theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
        <MaterialReactTable
          columns={objectArrayColumns}
          localization={
            i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
          }
          data={row?.value}
          enableEditing
          enablePagination={false}
          muiTableContainerProps={{
            sx: { maxHeight: "700px" },
          }}
          positionActionsColumn="last"
          editDisplayMode="modal"
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "left",
              },
              size: 50,
            },
          }}
          renderTopToolbarCustomActions={() => (
            <Tooltip
              arrow
              placement="right"
              title={userPermissions ? "" : t("noPermissionTooltip")}>
              <span>
                <Button
                  className="mrt-create-new-account-button"
                  id="new-config-button"
                  onClick={() => {
                    setTypeModal("new");
                    handleEditOpenModal();
                  }}
                  variant="contained"
                  disabled={!userPermissions}>
                  {t("addButton")}
                </Button>
              </span>
            </Tooltip>
          )}
          renderRowActions={(row) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip
                arrow
                placement="left"
                title={
                  userPermissions
                    ? t("editVarButton")
                    : t("noPermissionTooltip")
                }>
                <span>
                  <IconButton
                    disabled={!userPermissions}
                    onClick={() => {
                      setTypeModal("edit");
                      handleEditOpenModal(row);
                    }}>
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                arrow
                placement="right"
                title={
                  userPermissions ? t("deleteButton") : t("noPermissionTooltip")
                }>
                <span>
                  <IconButton
                    id="delete-icon"
                    sx={{ color: "deleteIcon.color" }}
                    onClick={() => {
                      setDeleteModalOpen(true);
                      setEditRow(row.row.original);
                    }}
                    disabled={!userPermissions}>
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )}
        />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default DetailedConfig;
